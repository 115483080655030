export default class Ticker {
  constructor () {
    if ($('ul').hasClass('breaking_news__list')) { this.homeTicker() }
    if ($('ul').hasClass('breaking_news__list-analysis')) { this.analysisTicker() }
  }

  homeTicker () {
    let currentNews = 0
    let elHeight = 25
    setInterval(() => {
      this.animateTicker(currentNews, elHeight)
      currentNews++
      if (currentNews > $('.breaking_news__item').length) {
        currentNews = 0
      }
    }, 5000)
  }

  animateTicker (currentNews, elHeight) {
    if (currentNews !== 0) {
      $('.breaking_news__list').animate({
        'margin-top': -elHeight * currentNews + 'px'
      }, 1000)
    }
    if (currentNews === $('.breaking_news__item').length) {
      $('.breaking_news__list').animate({
        'margin-top': '0'
      })
    }
  }

  analysisTicker () {
    let currentNews = 0
    let elHeight = 168
    setInterval(() => {
      this.analysisAnimateTicker(currentNews, elHeight)
      currentNews++
      if (currentNews > Math.floor($('.breaking_news__item').length / 3)) {
        currentNews = 0
      }
    }, 5000)
  }

  analysisAnimateTicker (currentNews, elHeight) {
    if (currentNews !== 0) {
      $('.breaking_news__list-analysis').animate({
        'margin-top': -elHeight * currentNews + 'px'
      }, 1000)
    }
    if (currentNews === Math.floor($('.breaking_news__item').length / 3)) {
      $('.breaking_news__list-analysis').animate({
        'margin-top': '0'
      })
    }
  }
}
